<template>
  <div
    class="container"
  >
    <div class="">
      <h1>Page not found</h1>
    </div>
  </div>
</template>

<script>
  export default {
    created() {

    },
  };
</script>

<style lang="scss" scoped>
  h1 {
    color: var(--unnamed-color-222222);
    text-align: center;
    font: normal normal normal 24px/24px Poppins;
    letter-spacing: 0.24px;
    color: #222222;
    font-weight: 700;
    text-align: center;
    padding: 12vh 0px;
  }
</style>
